import React, { useContext, useEffect, useRef, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
  PERMISSION_CI_DI,
  PERMISSION_CPI,
  PERMISSION_MONETIZATION,
  PERMISSION_NOTIFICATIONS,
  PERMISSION_PROJECT_SETTINGS,
  PERMISSION_READ_EXPERIMENTS,
  PERMISSION_SAYKIT_SETTINGS,
  PERMISSION_SUPPORT,
} from '../../../services/_utils';
import {
  IProjectHeaderContent,
  IProjectHeaderFeature,
  ProjectContext,
} from './models';
import styled from 'styled-components';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';

export const FeatureBar: React.FC = () => {
  const { projectId, versionId } = useParams();
  const { pathname } = useLocation();
  const { hasPermission, isAdmin } = useAuth();

  const { state } = useContext(ProjectContext);

  const features = featureList(
    projectId!,
    pathname,
    hasPermission,
    isAdmin,
    state.project!
  );
  const [visibleFeatures, setVisibleFeatures] = useState(features);
  const [hiddenFeatures, setHiddenFeatures] = useState(
    [] as IProjectHeaderFeature[]
  );

  const navbarRef = useRef<HTMLDivElement | null>(null);
  const featuresRefs = useRef<(HTMLAnchorElement | null)[]>([]);

  useEffect(() => {
    const handleResize = () => {
      if (navbarRef.current) {
        const navbarWidth = navbarRef.current.offsetWidth - 16;

        let totalWidth = 0;

        let visible: IProjectHeaderFeature[] = [];
        let hidden: IProjectHeaderFeature[] = [];

        features.forEach((feature, index) => {
          let linkWidth = featuresRefs.current[index]?.offsetWidth || 0;
          totalWidth += linkWidth;
          visible.push(feature);
        });

        if (totalWidth > navbarWidth) {
          while (totalWidth + 65 > navbarWidth && visible.length > 0) {
            hidden = [visible.pop()!, ...hidden];
            totalWidth -=
              featuresRefs.current[visible.length]?.offsetWidth || 0;
          }
        }

        setVisibleFeatures(visible);
        setHiddenFeatures(hidden);
      }
    };

    handleResize(); // Initial calculation
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [pathname]);

  return (
    <StyledNavbar
      as={Navbar}
      ref={navbarRef}
      isstyledborder={versionId !== undefined ? 1 : 0}
    >
      <Nav>
        {visibleFeatures.map((feature: IProjectHeaderFeature, idx: number) => (
          <Nav.Link
            as={Link}
            key={`${feature.eventKey}_${feature.label}`}
            eventKey={feature.eventKey}
            data-testid={feature.datatestid}
            to={feature.to}
            active={feature.active}
            ref={(el: any) => (featuresRefs.current[idx] = el)}
          >
            {feature.label}
          </Nav.Link>
        ))}
        {hiddenFeatures.length > 0 && (
          <NavDropdown title="More">
            {hiddenFeatures.map((feature: IProjectHeaderFeature) => (
              <Nav.Link
                as={Link}
                key={`${feature.eventKey}_${feature.label}`}
                eventKey={feature.eventKey}
                data-testid={feature.datatestid}
                to={feature.to}
                active={feature.active}
              >
                {feature.label}
              </Nav.Link>
            ))}
          </NavDropdown>
        )}
      </Nav>
    </StyledNavbar>
  );
};

const featureList = (
  projectId: string,
  pathname: string,
  hasPermission: (permission: string) => boolean,
  isAdmin: () => boolean,
  currentProject: IProjectHeaderContent
) => {
  let list = [
    {
      eventKey: '1',
      label: 'Versions',
      datatestid: 'lnkProjectHeaderVersions',
      to: '',
      active: pathname === `/projects/${projectId}`,
    } as IProjectHeaderFeature,
  ];

  if (
    isAdmin() ||
    (hasPermission(PERMISSION_READ_EXPERIMENTS) && currentProject.experiments)
  ) {
    list.push({
      eventKey: '2',
      label: 'Experiments',
      datatestid: 'lnkProjectHeaderExperiments',
      to: 'experiments',
      active: pathname.startsWith(`/projects/${projectId}/experiments`),
    } as IProjectHeaderFeature);
  }

  if (currentProject.globalLocalization) {
    list.push({
      eventKey: '3',
      label: 'Localization',
      datatestid: 'lnkProjectHeaderLocalizations',
      to: 'game_messages',
      active: pathname.startsWith(`/projects/${projectId}/game_messages`),
    } as IProjectHeaderFeature);
  }

  list.push({
    eventKey: '4',
    label: 'Devices',
    datatestid: 'lnkProjectHeaderDevices',
    to: 'devices',
    active: pathname.startsWith(`/projects/${projectId}/devices`),
  } as IProjectHeaderFeature);

  if (hasPermission(PERMISSION_SAYKIT_SETTINGS)) {
    list.push({
      eventKey: '5',
      label: 'SayKit',
      datatestid: 'lnkProjectHeaderSayKitSettings',
      to: 'saykit-settings',
      active: pathname.startsWith(`/projects/${projectId}/saykit-settings`),
    } as IProjectHeaderFeature);
  }

  list.push({
    eventKey: '6',
    label: 'Builds',
    datatestid: 'lnkProjectHeaderBuilds',
    to: 'builds',
    active: pathname.startsWith(`/projects/${projectId}/builds`),
  } as IProjectHeaderFeature);

  if (hasPermission(PERMISSION_PROJECT_SETTINGS)) {
    list.push({
      eventKey: '7',
      label: 'Settings',
      datatestid: 'lnkProjectHeaderSettings',
      to: 'settings',
      active: pathname.startsWith(`/projects/${projectId}/settings`),
    } as IProjectHeaderFeature);
  }

  if (hasPermission(PERMISSION_MONETIZATION)) {
    list.push({
      eventKey: '8',
      label: 'Monetization',
      datatestid: 'lnkProjectHeaderMonetization',
      to: 'monetization',
      active: pathname.startsWith(`/projects/${projectId}/monetization`),
    } as IProjectHeaderFeature);
  }

  if (hasPermission(PERMISSION_CPI)) {
    list.push({
      eventKey: '9',
      label: 'CPIs',
      datatestid: 'lnkProjectHeaderCPI',
      to: 'cpi',
      active: pathname.startsWith(`/projects/${projectId}/cpi`),
    } as IProjectHeaderFeature);
  }

  if (hasPermission(PERMISSION_SUPPORT)) {
    list.push({
      eventKey: '10',
      label: 'Support',
      datatestid: 'lnkProjectHeaderSupport',
      to: 'support',
      active: pathname.startsWith(`/projects/${projectId}/support`),
    } as IProjectHeaderFeature);
  }

  if (hasPermission(PERMISSION_NOTIFICATIONS)) {
    list.push({
      eventKey: '11',
      label: 'Notifications',
      datatestid: 'lnkProjectHeaderNotifications',
      to: 'notifications',
      active: pathname.startsWith(`/projects/${projectId}/notifications`),
    } as IProjectHeaderFeature);
  }

  list.push(
    {
      eventKey: '12',
      label: 'User Issues',
      datatestid: 'lnkProjectHeaderUserIssues',
      to: 'user-issues',
      active: pathname.startsWith(`/projects/${projectId}/user-issues`),
    } as IProjectHeaderFeature,
    {
      eventKey: '13',
      label: 'Log Validation',
      datatestid: 'lnkProjectHeaderLoggingCheck',
      to: 'log-validation',
      active: pathname.startsWith(`/projects/${projectId}/log-validation`),
    } as IProjectHeaderFeature,
    {
      eventKey: '14',
      label: 'Inapps',
      datatestid: 'lnkProjectHeaderInapps',
      to: 'inapps',
      active: pathname.startsWith(`/projects/${projectId}/inapps`),
    } as IProjectHeaderFeature
  );

  if (hasPermission(PERMISSION_CI_DI)) {
    list.push({
      eventKey: '15',
      label: 'CI/DI',
      datatestid: 'lnkProjectHeaderCidi',
      to: 'cidi',
      active: pathname.startsWith(`/projects/${projectId}/cidi`),
    } as IProjectHeaderFeature);
  }

  return list;
};

const StyledNavbar = styled.div<{ isstyledborder: number }>`
  background: #f0f2f4;
  margin-top: 0.5rem;
  padding: 0.75rem 1rem;

  border-radius: ${(props: { isstyledborder: number }) =>
    props.isstyledborder === 1 ? '16px 16px 16px 0px' : '16px 16px 16px 16px'};

  a.nav-link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.35rem;
    text-decoration: none;
    color: #6c757d;
  }

  .dropdown-menu {
    background: #f0f2f4;
    border-radius: 8px;
    left: -4.1rem;
  }
`;
