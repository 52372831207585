import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { DropdownComponent } from '../../../components/Dropdown';
import { IProjectHeaderContent, ProjectContext } from './models';
import DropdownItem from 'react-bootstrap/DropdownItem';
import { MainColor, prettifyName } from '../../../services/_utils';
import { notify } from '../../../components/Toast/Toast';
import { IconSteam } from '../../../components/IconSteam/IconSteam';
import { IconApple } from '../../../components/IconApple/IconApple';
import { IconAndroid } from '../../../components/IconAndroid/IconAndroid';
import { ChevronDown } from 'react-bootstrap-icons';
import { IconAppleChina } from '../../../components/IconAppleChina/IconAppleChina';
import { IconHuawei } from '../../../components/IconHuawei/IconHuawei';
import { IconAmazon } from '../../../components/IconAmazon/IconAmazon';
import { IconXiaomi } from '../../../components/IconXiaomi/IconXiaomi';
import { IconAndroidChina } from '../../../components/IconAndroidChina/IconAndroidChina';
import { DropdownDivider, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';
import { SearchInputComponent } from '../../../components/SearchInput';
import useAuth from '../../../hooks/useAuth';
import { cloneDeep } from 'lodash';
import { IconWeb } from '../../../components/IconWeb/IconWeb';
import { useNavigate } from 'react-router-dom';

const DROPDOWN_MENU_HEIGHT = 550;
const DROPDOWN_STEP_APP_LOAD = 500;

export const GameSwitcher: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useContext(ProjectContext);
  const { isAdmin, isQaManager, getCompanies } = useAuth();

  const [search, setSearch] = useState('');
  const [visibleList, setVisibleList] = useState(
    state.list.slice(0, Math.min(10, state.list.length))
  );
  const [hoverStoreLink, setHoverStoreLink] = useState(false);

  const currentProject = state.project!;

  const onSelect = (e: SyntheticEvent<HTMLDivElement>) => {
    navigate(`/projects/${e.toString()}`);
  };

  const onCopy = (value: string) => {
    navigator.clipboard.writeText(value).then(() => {
      notify('info', 'Value has been successfully copied');
    });
  };

  const list = () => {
    if (search.length > 0) {
      return state.list.filter((p) => p._filter.includes(search.toLowerCase()));
    }

    return visibleList;
  };

  const showCompany = isAdmin() || isQaManager() || getCompanies().length > 1;

  // это для того чтобы в сафари не тормозил дропдаун при первом открытии
  useEffect(() => {
    if (!visibleList.length || visibleList.length === state.list.length) return;

    let upd = cloneDeep(visibleList);

    upd.push(
      ...state.list.slice(
        upd.length,
        Math.min(upd.length + DROPDOWN_STEP_APP_LOAD, state.list.length)
      )
    );

    const timerId = setInterval(() => {
      setVisibleList(upd);
    }, 500);

    return () => clearInterval(timerId);
  }, [visibleList]);

  return (
    <StyledGameSwitcher
      onMouseOver={() => setHoverStoreLink(true)}
      onMouseLeave={() => setHoverStoreLink(false)}
    >
      <DropdownComponent
        autoClose={true}
        title={<Title />}
        classnames="glowing-border me-3"
        renderMenuOnMount={true}
        onSelect={onSelect}
      >
        {state.gamepack.map((p: IProjectHeaderContent) => (
          <DropdownItem key={p.id} eventKey={p.id}>
            <ProjectNameWithIcon project={p} />
          </DropdownItem>
        ))}
        {state.gamepack.length > 0 && <DropdownDivider />}
        <div className="position-relative">
          <div className="searchArea px-3">
            <SearchInputComponent
              setValue={setSearch}
              datatestid={'txtProjectHeaderGameSwitcherSearch'}
            />
          </div>
          <div
            style={{
              overflowY: 'scroll',
              overflowX: 'hidden',
              paddingTop: '65px',
              paddingBottom: '10px',
              height:
                DROPDOWN_MENU_HEIGHT - state.gamepack.length * 44 - 20 + 'px', // menu height - gamepacks - divider height
            }}
          >
            {list().map((p: IProjectHeaderContent) => (
              <DropdownItem key={p.id} eventKey={p.id}>
                <ProjectNameWithIcon project={p} />
              </DropdownItem>
            ))}
          </div>
        </div>
      </DropdownComponent>
      <OverlayTrigger overlay={<Tooltip>click to copy</Tooltip>}>
        <span
          className="text-muted me-3"
          role={'button'}
          onClick={() => onCopy(currentProject.appKey)}
        >
          {currentProject.appKey}
        </span>
      </OverlayTrigger>
      {showCompany && (
        <OverlayTrigger overlay={<Tooltip>click to copy</Tooltip>}>
          <span
            className="text-muted me-3"
            role={'button'}
            onClick={() => onCopy(currentProject.company)}
          >
            {currentProject.company}
          </span>
        </OverlayTrigger>
      )}
      {!!currentProject.storeUrl &&
        storeUrlView(currentProject, hoverStoreLink, 22)}
    </StyledGameSwitcher>
  );
};

const Title: React.FC = () => {
  const { state } = useContext(ProjectContext);

  const currentProject = state.project!;

  const prettyName = prettifyName(currentProject.name);

  return (
    <h2 className="title d-flex align-items-center m-0">
      <div className="me-2">{projectIcon(currentProject, 36)}</div>
      <div style={{ color: 'black' }}>{prettyName}</div>
      <div className="ms-2">{platformIcon(currentProject)}</div>
      <ChevronDown size={16} className="ms-3" />
    </h2>
  );
};

const ProjectNameWithIcon: React.FC<{ project: IProjectHeaderContent }> = ({
  project,
}) => {
  return (
    <div className="d-flex align-items-center my-1">
      <div className="me-2">{projectIcon(project)}</div>
      <div>{prettifyName(project.name)}</div>
      <div
        className="ms-2 d-flex justify-content-center"
        style={{ width: '30px' }}
      >
        {platformIcon(project)}
      </div>
    </div>
  );
};

const StyledGameSwitcher = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  user-select: none;

  .dropdown-toggle {
    overflow: hidden;
    width: auto; /* Автоматическая ширина */
    max-width: 100%;
    min-height: 45px;
    height: 45px;
    padding-left: 3px;
    padding-right: 10px;
    display: inline-block;
    white-space: nowrap; /* Запрет переноса */
    text-overflow: ellipsis; /* Троеточие при обрезке */

    &::after {
      display: none;
    }

    border: none;
    box-shadow: none !important;
  }

  @keyframes slideIn {
    from {
      height: 0;
      opacity: 0;
    }
    to {
      height: 500px;
      opacity: 1;
    }
  }

  .dropdown-menu {
    overflow-y: hidden;
    overflow-x: hidden;
    animation: slideIn 0.5s ease;
    min-height: ${DROPDOWN_MENU_HEIGHT}px;
    height: ${DROPDOWN_MENU_HEIGHT}px;
    min-width: 400px;
  }

  .searchArea {
    position: absolute;
    width: 100%;
    z-index: 1;
    top: -2px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(4px);
  }

  @keyframes borderGlow {
    0% {
      border-bottom: 2px solid transparent;
    }
    50% {
      border-bottom: 2px solid ${MainColor};
    }
    100% {
      border-bottom: 2px solid transparent;
    }
  }

  .glowing-border {
    max-width: 99%;
    display: inline-block;
    border-bottom: 2px solid transparent;
    border-radius: 10px;
    animation: borderGlow 3s infinite; /* Анимация длится 2 секунды и повторяется бесконечно */
  }
`;

export const projectIcon = (
  project: IProjectHeaderContent,
  size: number = 28
) => {
  return (
    <img
      src={`https://storeapi.saygames.io/api/v1/icon?appkey=${
        project.appKey
      }&return_empty_on_error=true&size=small${
        project.china ? '&country=cn' : ''
      }`}
      style={{ borderRadius: '8px' }}
      alt={`${project.appKey}_icon`}
      loading="lazy"
      height={size}
      width={size}
    />
  );
};

export const platformIcon = (project: IProjectHeaderContent) => {
  const iOS = '(iOS)';
  const Android = '(Android)';
  const Web = '(Web)';
  const China = 'China';
  const Huawei = 'Huawei';
  const Xiaomi = 'Xiaomi';
  const Amazon = 'Amazon';
  const Steam = 'Steam';

  if (project.name.indexOf(Web) !== -1) {
    return <IconWeb size={22} />;
  }

  if (project.name.indexOf(Huawei) !== -1) {
    return <IconHuawei size={24} />;
  }

  if (project.name.indexOf(Steam) !== -1) {
    return <IconSteam size={23} />;
  }

  if (project.name.indexOf(Amazon) !== -1) {
    return <IconAmazon size={26} />;
  }

  if (project.name.indexOf(Xiaomi) !== -1) {
    return <IconXiaomi size={24} />;
  }

  if (project.name.indexOf(Steam) !== -1) {
    return <IconSteam size={26} />;
  }

  if (project.name.indexOf(iOS) !== -1) {
    if (project.name.indexOf(China) !== -1) {
      return <IconAppleChina size={24} />;
    }
    return <IconApple size={24} />;
  }

  if (project.name.indexOf(Android) !== -1) {
    if (project.name.indexOf(China) !== -1) {
      return <IconAndroidChina size={28} />;
    }
    return <IconAndroid size={28} />;
  }

  return null;
};

const storeUrlView = (
  project: IProjectHeaderContent,
  hover: boolean,
  size: number = 22
) => {
  const source = () => {
    if (project.platform === 'ios') {
      return '/img/app_store_logo.png';
    }

    if (project.platform === 'android') {
      if (project.storeUrl.includes('play.google.com')) {
        return '/img/play_google_logo.png';
      }
      if (project.storeUrl.includes('amazon')) {
        return '/img/amazon_store_icon.png';
      }
      if (project.storeUrl.includes('huawei')) {
        return '/img/huawei_store_icon.png';
      }
    }
  };

  return (
    <a
      data-testid="lnkProjectHeaderGameSwitcherStoreUrl"
      className="d-flex align-items-center text-decoration-none"
      href={project.storeUrl}
      rel="noreferrer"
      target="_blank"
    >
      <StyledStoreImg
        src={source()}
        alt="huawei_store_logo"
        height={size}
        width={size}
        hover={hover}
      />
      <span className="text-muted ms-2">{project.storeTitle}</span>
    </a>
  );
};

const StyledStoreImg = styled.img<{ hover: boolean }>`
  filter: ${(props) => (props.hover ? 'none' : 'grayscale(100%)')};
`;
