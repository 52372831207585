import { useFetch } from '../hooks/useFetch';

export const useGlobalService = () => {
  const { request, loading, error } = useFetch();

  const getHealthData = async () => {
    return await request('health');
  };

  const getGamesInfo = async () => {
    return await request('games-info');
  };

  const exportGamesInfo = async (gamesInfo: object) => {
    return await request(
      'games-info/export',
      'POST',
      JSON.stringify(gamesInfo),
      true
    );
  };

  const exportGamesInfoContractNames = async () => {
    return await request('games-info/export_contract_names', 'GET', null, true);
  };

  const importGamesInfoContractNames = async (form: FormData) => {
    return await request(
      'games-info/export_contract_names',
      'POST',
      form,
      false,
      {
        Login: localStorage.getItem('login')!,
        Role: localStorage.getItem('role')!,
        Hash: localStorage.getItem('hash')!,
        Accept: 'text/csv',
      }
    );
  };

  const getHelp = async (needSync: boolean) => {
    let uri = `help`;
    if (needSync) {
      uri += `?sync=true`;
    }
    return await request(uri);
  };

  const getHelpFileContent = async (fileId: string, needSync: boolean) => {
    let uri = `help/${fileId}`;
    if (needSync) {
      uri += `?sync=true`;
    }
    return await request(uri);
  };

  const getCreatives = async (qb: Partial<{}>) => {
    return await request(`creatives2?filters=${JSON.stringify(qb)}`);
  };

  const getTotals = async () => {
    return await request(`totals`);
  };

  const getSaykitBlacklist = async () => {
    return await request(`saykit/blacklist`);
  };

  const addSaykitBlacklist = async (obj: object) => {
    return await request(`saykit/blacklist`, 'POST', JSON.stringify(obj));
  };

  const updateSaykitBlacklist = async (obj: object) => {
    return await request(`saykit/blacklist`, 'PUT', JSON.stringify(obj));
  };

  const deleteSaykitBlacklist = async (saykitId: number) => {
    return await request(`saykit/blacklist/${saykitId}`, 'DELETE');
  };

  const getUnityList = async () => {
    return await request(`unity`);
  };

  const addUnityToList = async (obj: object) => {
    return await request(`unity`, 'POST', JSON.stringify(obj));
  };

  const updateUnityToList = async (obj: object) => {
    return await request(`unity`, 'PUT', JSON.stringify(obj));
  };

  const deleteUnityToList = async (saykitId: number) => {
    return await request(`unity/${saykitId}`, 'DELETE');
  };

  const sayDiscoveryGuard = async () => {
    return await request(`saydiscovery-guard`);
  };

  const runtimeConfigDashboard = async () => {
    return await request(`runtime-config`);
  };

  const runtimeConfigDashboardDelete = async (obj: object) => {
    return await request(`runtime-config`, 'DELETE', JSON.stringify(obj));
  };

  return {
    loading,
    error,
    getHealthData,
    getGamesInfo,
    exportGamesInfo,
    exportGamesInfoContractNames,
    importGamesInfoContractNames,
    getHelp,
    getHelpFileContent,
    getCreatives,
    getTotals,
    getSaykitBlacklist,
    addSaykitBlacklist,
    updateSaykitBlacklist,
    deleteSaykitBlacklist,
    getUnityList,
    addUnityToList,
    updateUnityToList,
    deleteUnityToList,
    sayDiscoveryGuard,
    runtimeConfigDashboard,
    runtimeConfigDashboardDelete,
  };
};
